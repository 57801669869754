import { Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const FeaturedCard = ({ blog }) => {
  const parserOptions = {
    replace: (() => {
      var count = 0;
      return (domNode) => {
        if ((domNode.type === "tag" && domNode.name === "img") || count > 5) {
          return <></>;
        }
        count++;
      };
    })(),
  };
  const categories = blog?.categories?.data;
  const category_slug = (categories && categories[0]?.attributes?.slug) || "";

  return (
    <div>
      <Link
        to={`/blog/${category_slug}/${blog?.slug}/`}
        className="featured-blog"
      >
        {blog?.featuredImage && (
          <div className="featured-blog__image">
            <GatsbyImage
              image={getImage(blog.featuredImage)}
              alt={blog?.title}
              objectFit="fill"
            />
          </div>
        )}
        <div className="featured-blog__body">
          <div className="featured-blog__body__categories">
            {categories?.map((category) => (
              <p
                className="featured-blog__body__categories__category"
                key={category?.attributes.name}
              >
                {category?.attributes.name}
              </p>
            ))}
          </div>
          <h2 className="featured-blog__body__title">{blog?.title}</h2>
          {blog?.content && (
            <div className="featured-blog__body__summary">
              {parse(blog.content, parserOptions)}
            </div>
          )}
          {blog?.releaseDate && (
            <p className="featured-blog__body__date">{blog.releaseDate}</p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default FeaturedCard;
