import { Link } from "gatsby";
import React from "react";

const Pagination = ({ pageContext, prefixURL }) => {
  const currentPageNumber = pageContext?.humanPageNumber;
  const totalPages = pageContext?.numberOfPages;
  const startPage = currentPageNumber - 2;
  const endPage = currentPageNumber + 3;
  var isPlaceholderDisplayed = false;
  return (
    <div className="pagination">
      {currentPageNumber === 1 ? (
        <div>
          <span className="hn-p2">前へ</span>
        </div>
      ) : (
        <Link
          to={pageContext.previousPagePath}
          className="pagination__previous"
        >
          前へ
        </Link>
      )}
      {new Array(totalPages).fill(1).map((_, index) => {
        const pageNumber = index + 1;
        if (
          (pageNumber >= startPage && pageNumber <= endPage) ||
          totalPages - pageNumber < 2 ||
          pageNumber < 3
        ) {
          return (
            <Link
              to={`${prefixURL}${pageNumber !== 1 ? pageNumber : ""}`}
              key={index}
            >
              <div
                className={`pagination__item ${
                  pageNumber === pageContext.humanPageNumber ? "active" : ""
                }`}
              >
                {pageNumber}
              </div>
              {(isPlaceholderDisplayed = false)}
            </Link>
          );
        } else if (!isPlaceholderDisplayed) {
          return (
            <div className="pagination__placeholder" key={index}>
              <p>.....</p>
              {(isPlaceholderDisplayed = true)}
            </div>
          );
        }
      })}
      {currentPageNumber === totalPages ? (
        <div>
          <span className="hn-p2">次へ</span>
        </div>
      ) : (
        <Link to={pageContext.nextPagePath} className="pagination__next">
          次へ
        </Link>
      )}
    </div>
  );
};

export default Pagination;
