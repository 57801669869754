import { Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const BlogCard = ({ blog }) => {
  const categories = blog?.categories?.data;
  const parserOptions = {
    replace: (() => {
      var count = 0;
      return (domNode) => {
        if ((domNode.type === "tag" && domNode.name === "img") || count > 6) {
          return <></>;
        }
        count++;
      };
    })(),
  };
  return (
    <div className="blog-card">
      <Link to={`/blog/${categories[0]?.attributes.slug}/${blog?.slug}/`}>
        <div className="blog-card__categories">
          {categories?.map((category) => (
            <p
              className="blog-card__categories__category"
              key={category?.attributes?.name}
            >
              {category?.attributes?.name}
            </p>
          ))}
        </div>
        <div className="blog-card__image">
          <GatsbyImage
            image={getImage(blog.featuredImage)}
            alt={blog?.title}
            objectFit="fill"
          />
        </div>
        <h2 className="blog-card__title">{blog?.title}</h2>
        <div className="blog-card__summary">
          {parse(blog?.content, parserOptions)}
        </div>
        <p className="blog-card__date">{blog.releaseDate}</p>
      </Link>
    </div>
  );
};

export default BlogCard;
