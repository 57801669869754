import { graphql } from "gatsby";
import React from "react";
import BlogCard from "../../components/blog/blogCard/BlogCard";
import FeaturedCard from "../../components/blog/featuredCard/FeaturedCard";
import Pagination from "../../components/common/pagination/Pagination";
import Meta from "../../components/seo/Meta";
import Container from "../../layout/container";

const FilterByCategory = (props) => {
  const posts = props?.data?.allBlogs?.nodes;
  const featuredPost = posts[0];
  const { pageContext } = props;
  const siteUrl = props?.data?.allSite?.nodes[0]?.siteMetadata?.siteUrl;

  const metaData = {
    title: `${pageContext.categoryName}${
      pageContext?.humanPageNumber > 1
        ? " - Page " + pageContext?.humanPageNumber
        : ""
    }｜Harness正規販売代理店 DXable`,
    url: `${siteUrl}${props.path}/`,
  };
  return (
    <>
      <Meta meta={metaData} isEmptyDescription={true} />
      <Container>
        <div className="hn-container py-0">
          <div className="blog">
            <div className="blog__heading">
              <h1 className="blog__heading__main">Harness Blog</h1>
            </div>
            <div className="blog__body">
              <FeaturedCard blog={featuredPost} />
              <div className="blog__body__other-blogs">
                {posts.map((post, index) => {
                  if (index !== 0) {
                    return <BlogCard blog={post} key={post?.id} />;
                  }
                })}
              </div>
            </div>
            <div className="blog__footer">
              <Pagination
                prefixURL={`/blog/${pageContext?.categorySlug}/`}
                pageContext={pageContext}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export const blogPostsQuery = graphql`
  query fetchBlogPostsByCategory(
    $categorySlug: String!
    $skip: Int!
    $limit: Int!
  ) {
    allBlogs(
      skip: $skip
      limit: $limit
      sort: { fields: releaseDate, order: DESC }
      filter: {
        categories: {
          data: { elemMatch: { attributes: { slug: { eq: $categorySlug } } } }
        }
      }
    ) {
      nodes {
        categories {
          data {
            attributes {
              name
              slug
            }
          }
        }
        title
        slug
        releaseDate(formatString: "LL", locale: "ja")
        id
        content
        featuredImage {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
          }
        }
        seo {
          feature_image {
            data {
              attributes {
                alternativeText
                caption
              }
            }
          }
          id
          title
        }
      }
    }
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default FilterByCategory;
